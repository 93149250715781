import { Link } from '@remix-run/react';
import RichText from './RichText.jsx';
import { companyShape } from '../utils/shapes.js';

export default function Footer({ company }) {
  return (
    <footer className="site-footer">
      <div className="p-4">
        {company?.footer_message ? (
          <div className="text-center">
            <RichText html={company.footer_message} />
            <br />
            <p className="bmm-branded-footer">
              The platform is managed and supported by Best Money Moves, a leading provider of personal financial
              wellness solutions.
            </p>
            {company?.has_bmm_customer_support_email_reminder && (
              <a
                className="h6 font-weight-bold d-flex justify-content-center flex-wrap text-center mb-0"
                href="mailto:customersupport@bestmoneymoves.com"
              >
                <span className="font-weight-normal me-1 text-center">Need Help?:</span>
                CustomerSupport@bestmoneymoves.com
              </a>
            )}
          </div>
        ) : (
          <>
            <p className="text-center">
              All information in your account is private. And, by that we mean, we&apos;ll never share what you enter
              with anyone. And we mean never! We keep your information strictly confidential.
            </p>
            <a
              className="h6 font-weight-bold d-flex justify-content-center flex-wrap text-center mb-0"
              href="mailto:customersupport@bestmoneymoves.com"
            >
              <span className="font-weight-normal me-1 text-center">Need Help?:</span>
              CustomerSupport@bestmoneymoves.com
            </a>
          </>
        )}
      </div>

      <div className="bottom">
        <span>Copyright &copy; 2024 Best Money Moves</span>
        <ul>
          <li>
            <Link to="/privacy">Privacy</Link>
          </li>
          <li>
            <Link to="/terms-and-conditions">Terms & Conditions</Link>
          </li>
        </ul>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  company: companyShape,
};
