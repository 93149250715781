import PropTypes from 'prop-types';
import { convertScoreToPercentage } from '../../utils/convertScoreToPercentage.js';
import { getFinancialWellnessMessage } from '../../utils/getFinancialWellnessMessage.js';

const Step3End = ({ fetcher }) => {
  // Get the data from the response data.
  const score = fetcher.data?.result?.assessmentEvent?.score;

  const financialScorePercentage = convertScoreToPercentage(score);

  return (
    <div className="text-center pt-md-5">
      <h2 className="mb-3">Your overall Financial Wellness Score is</h2>
      <p className="financial-wellness-stepper__score">{Math.round(financialScorePercentage)}/100</p>
      <p className="text-secondary">{getFinancialWellnessMessage(Math.round(financialScorePercentage))}</p>
    </div>
  );
};

Step3End.propTypes = {
  fetcher: PropTypes.object,
};

export default Step3End;
