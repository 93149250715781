import { useEffect, useState, useContext } from 'react';
import React from 'react';
import PropTypes from 'prop-types';
import {
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Button,
  FormText,
  Row,
  Col,
  Alert,
  PopoverBody,
  UncontrolledPopover,
} from 'reactstrap';
import { Link, useRouteLoaderData } from '@remix-run/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import {
  VerifyContext,
  VerifyDispatchContext,
  setShowEmailValidationForm,
  setEmailVerificationCode,
  verifyEmail,
  submitEmailValidationCode,
} from '../../contexts/verifyContext';
import PasswordInput from '../../components/PasswordInput';
import RequestEmailCodeForm from './RequestEmailCodeForm';
import { HOME_MORTGAGE, HOME_NO_MORTGAGE, RENT, OTHER } from '../../utils/constants';
import { validatePassword, validateName, validateEmail } from '../../utils/validators';
import './onboarding.css';

const Step1A = ({ formValues, setFormValues, canProceed, formErrors, user, profile, setFormErrors }) => {
  const { company } = useRouteLoaderData('root') ?? {};
  const [passwordValid, setPasswordValid] = useState(false);
  const [confirmPasswordValid, setConfirmPasswordValid] = useState(false);

  const verifyState = useContext(VerifyContext);
  const verifyDispatch = useContext(VerifyDispatchContext);

  const {
    showEmailValidationForm,
    showEmailCodeForm,
    verifyEmailFetcher,
    verifyEmailCheckFetcher,
    emailVerificationCode,
  } = verifyState;

  // Either use the values from the user object, or the values from the final verify response.
  const confirmedEmailValue =
    (user.email_verified ? user.email : null) || verifyEmailCheckFetcher.data?.confirmedEmail || null;

  const clearFormErrors = (fieldName) => {
    const hasErrors = formErrors?.children[fieldName]?.errors;
    if (hasErrors) {
      setFormErrors((prev) => {
        prev.children[fieldName].errors = [];

        return prev;
      });
    }
  };

  const handleChange = (e) => {
    setFormValues((prev) => ({
      ...prev,
      [e.target.name]: e.target.value?.trim(),
    }));

    clearFormErrors(e.target.name);
  };

  const handleEmailChange = (e) => {
    verifyDispatch(setShowEmailValidationForm(!!e.target.value && e.target.value !== confirmedEmailValue));
    handleChange(e);
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;

    setPasswordValid(validatePassword(newPassword));
    setFormValues((prev) => ({
      ...prev,
      [e.target.name]: newPassword,
    }));
  };

  const handleConfirmPasswordChange = (e) => {
    const newPassword = e.target.value;

    // confirm password has to match password.
    setConfirmPasswordValid(validatePassword(newPassword) && newPassword === formValues?.new_password);
    setFormValues((prev) => ({
      ...prev,
      [e.target.name]: newPassword,
    }));
  };

  const handleTermsConditionsChanged = (e) => {
    setFormValues((prev) => ({
      ...prev,
      [e.target.name]: e.target.checked,
    }));
  };

  const handleEmailCodeChanged = (e) => verifyDispatch(setEmailVerificationCode(e.target.value));
  const handleRequestEmailCode = () => verifyDispatch(verifyEmail(formValues.company_email, verifyEmailFetcher));

  const isNewEmailVerified =
    verifyEmailCheckFetcher.data?.success &&
    !!formValues.company_email &&
    formValues.company_email === confirmedEmailValue;
  const isEmailVerified = user?.email_verified || isNewEmailVerified;

  // Set canProceed based on the state of this step's form fields.
  useEffect(() => {
    const { first_name, last_name, company_email, mobile, home_zip, demographic, has_read_terms_and_conditions } =
      formValues;

    const passwordsValid = user.has_set_password || (passwordValid && confirmPasswordValid);

    // Companies with blind single sign on only require zip and demographic to be set.
    let requiredFieldsPresent = false;
    if (company.has_blind_single_sign_on) {
      requiredFieldsPresent = home_zip && demographic;
    } else {
      requiredFieldsPresent =
        first_name &&
        last_name &&
        company_email &&
        mobile &&
        home_zip &&
        demographic &&
        passwordsValid &&
        has_read_terms_and_conditions;
    }

    canProceed(requiredFieldsPresent);
  }, [
    canProceed,
    company.has_blind_single_sign_on,
    confirmPasswordValid,
    formValues,
    isEmailVerified,
    passwordValid,
    user.has_set_password,
  ]);

  const getErrorsByFieldName = (fieldName) => formErrors?.children[fieldName]?.errors;

  const isEmailInvalid =
    !validateEmail(formValues.company_email) || (getErrorsByFieldName('company_email') || []).length > 0;

  return (
    <>
      {!!formErrors && (
        <Row>
          <Col xs={12}>
            <Alert color="danger">
              There was a problem saving your profile. Please check the error messages below.
            </Alert>
          </Col>
        </Row>
      )}
      {!company.has_blind_single_sign_on && (
        <>
          <Row>
            <Col xs={6}>
              <FormGroup>
                <Label for="first_name">First Name</Label>
                <Input
                  id="first_name"
                  name="first_name"
                  type="text"
                  onChange={handleChange}
                  value={formValues.first_name || ''}
                  invalid={
                    !validateName(formValues.first_name) || (getErrorsByFieldName('first_name') || []).length > 0
                  }
                />
                <FormFeedback>This is not a valid first name!</FormFeedback>
              </FormGroup>
            </Col>
            <Col xs={6}>
              <FormGroup>
                <Label for="last_name">Last Name</Label>
                <Input
                  id="last_name"
                  name="last_name"
                  type="text"
                  onChange={handleChange}
                  value={formValues.last_name || ''}
                  invalid={!validateName(formValues.last_name) || (getErrorsByFieldName('last_name') || []).length > 0}
                />
                <FormFeedback>This is not a valid last name!</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xs={verifyEmailFetcher.data?.success && !verifyEmailCheckFetcher.data?.success ? 6 : 12}>
              <FormGroup className="email-form-group">
                <span>
                  <Label for="company_email">Email</Label>
                  <RequestEmailCodeForm
                    showEmailValidationForm={showEmailValidationForm}
                    email={formValues.company_email}
                    user={user}
                    confirmedEmailValue={confirmedEmailValue}
                    isEmailInvalid={!validateEmail(formValues.company_email)}
                    verifyEmail={() => verifyDispatch(verifyEmail(formValues.company_email, verifyEmailFetcher))}
                    isEmailVerified={isNewEmailVerified}
                  />
                </span>
                <Input
                  id="company_email"
                  name="company_email"
                  type="email"
                  onChange={handleEmailChange}
                  value={formValues.company_email || ''}
                  invalid={isEmailInvalid || (verifyEmailFetcher.data && !verifyEmailFetcher.data.success)}
                />
                <FormFeedback>This is not a valid email! {verifyEmailFetcher.data?.message}</FormFeedback>
                <FormText>
                  {!verifyEmailCheckFetcher.data?.success &&
                    verifyEmailFetcher.data?.success &&
                    verifyEmailFetcher.data?.message}
                </FormText>
              </FormGroup>
            </Col>
            {showEmailCodeForm && !verifyEmailCheckFetcher.data?.success && (
              <Col xs={6}>
                <Row>
                  <Col xs={8}>
                    <FormGroup>
                      <span>
                        <Label for="emailVerificationCode">Enter code</Label>
                        <Button size="sm" color="link" className="pt-0 pb-0 ps-2 pe-0" onClick={handleRequestEmailCode}>
                          Resend Code
                        </Button>
                      </span>
                      <Input
                        id="emailVerificationCode"
                        name="emailVerificationCode"
                        type="text"
                        onChange={handleEmailCodeChanged}
                        required
                        placeholder="8-digit code"
                        invalid={
                          !!emailVerificationCode &&
                          verifyEmailCheckFetcher.data &&
                          !verifyEmailCheckFetcher.data?.success
                        }
                      />
                      <FormFeedback>This is not a valid code!</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col xs={4} className="d-flex align-items-end">
                    <FormGroup>
                      <Button
                        size="sm"
                        color="primary"
                        className="ps-3 pe-3"
                        onClick={() =>
                          submitEmailValidationCode(
                            formValues.company_email,
                            emailVerificationCode,
                            verifyEmailCheckFetcher
                          )
                        }
                        disabled={
                          !emailVerificationCode?.trim() ||
                          verifyEmailCheckFetcher.state === 'loading' ||
                          verifyEmailCheckFetcher.state === 'submitting'
                        }
                      >
                        Submit
                      </Button>
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
            )}
          </Row>
        </>
      )}

      <Row>
        <Col xs={6}>
          <FormGroup>
            <Label for="home_zip">Home Zip</Label>
            <Input
              id="home_zip"
              name="home_zip"
              type="text"
              onChange={handleChange}
              minLength={5}
              maxLength={5}
              value={formValues.home_zip || ''}
              invalid={
                typeof formValues.home_zip !== 'undefined' &&
                (!formValues.home_zip ||
                  !formValues.home_zip.match(/\d{5}/) ||
                  (getErrorsByFieldName('home_zip') || []).length > 0)
              }
            />
            <FormFeedback>Zip codes must contain 5 digits.</FormFeedback>
          </FormGroup>
        </Col>
        <Col xs={6}>
          <FormGroup>
            <Label for="demographic">Select</Label>
            <Input
              id="demographic"
              name="demographic"
              type="select"
              onChange={handleChange}
              value={formValues.demographic || 'Select Living Situation'}
              invalid={
                typeof formValues.demographic !== 'undefined' &&
                (!formValues.demographic ||
                  formValues.demographic === 'Select Living Situation' ||
                  (getErrorsByFieldName('demographic') || []).length > 0)
              }
            >
              <option disabled>Select Living Situation</option>
              <option value={HOME_MORTGAGE}>Home Owner with Mortgage</option>
              <option value={HOME_NO_MORTGAGE}>Home Owner without Mortgage</option>
              <option value={RENT}>Renter</option>
              <option value={OTHER}>Other</option>
            </Input>
          </FormGroup>
        </Col>
      </Row>
      {!user.has_set_password && (
        <>
          <Row>
            <Col xs={12} md={6}>
              <FormGroup>
                <Label for="new_password">New Password</Label>
                <PasswordInput
                  id="new_password"
                  name="new_password"
                  type="password"
                  placeholder="New Password"
                  invalid={typeof formValues?.new_password !== 'undefined' && !passwordValid}
                  onChange={handlePasswordChange}
                />
                <FormFeedback>
                  {formValues?.new_password !== formValues?.confirm_password && 'Passwords do not match.'}
                  {formValues?.new_password === '' && 'New password is required.'}
                  {formErrors?.children?.new_password?.children?.first?.errors?.join(' ')}
                </FormFeedback>
              </FormGroup>
            </Col>
            <Col xs={12} md={6}>
              <FormGroup>
                <Label for="confirm_password">Confirm Password</Label>
                <PasswordInput
                  id="confirm_password"
                  name="confirm_password"
                  type="password"
                  placeholder="Confirm Password"
                  invalid={typeof formValues?.confirm_password !== 'undefined' && !confirmPasswordValid}
                  onChange={handleConfirmPasswordChange}
                />
                <FormFeedback>
                  {formValues?.confirm_password === '' && 'Password confirmation is required.'}
                </FormFeedback>
              </FormGroup>
            </Col>
          </Row>
          {!passwordValid && (
            <Row>
              <Col xs={12}>
                <FormGroup>
                  <FormText color="danger">
                    Passwords must be at least eight characters and include an upper case letter, a lower case letter, a
                    number, and a special symbol.
                  </FormText>
                </FormGroup>
              </Col>
            </Row>
          )}
        </>
      )}
      <Row>
        <Col xs={12}>
          <FormGroup className="mb-4" check>
            <Input
              type="checkbox"
              id="has_read_terms_and_conditions"
              name="has_read_terms_and_conditions"
              defaultChecked={profile.has_read_terms_and_conditions}
              onChange={handleTermsConditionsChanged}
            />
            <Label className="mx-2 fw-normal">
              I acknowledge that I&apos;ve read and agree to the{' '}
              <Link to="/terms-and-conditions" target="_blank">
                Terms and Conditions
              </Link>{' '}
              and I consent to receive emails and other electronic communications from Best Money Moves.
              <FontAwesomeIcon
                icon={faQuestionCircle}
                id="termsConditionsPopover"
                className="ms-1 text-primary cursor-pointer"
              />
            </Label>
            <UncontrolledPopover flip target="termsConditionsPopover" placement="right" trigger="focus">
              <PopoverBody className="text-center">
                <p className="mb-2" style={{ fontSize: '.8rem' }}>
                  These communications may include disclosures and notices required by law, login credentials or
                  password resend requests and other important information about your BMM Account.
                </p>
              </PopoverBody>
            </UncontrolledPopover>
            <FormFeedback>{formErrors?.children?.has_read_terms_and_conditions?.errors?.join(' ')}</FormFeedback>
          </FormGroup>
        </Col>
      </Row>
    </>
  );
};

Step1A.propTypes = {
  canProceed: PropTypes.func,
  formErrors: PropTypes.shape({
    children: PropTypes.shape({
      has_read_terms_and_conditions: PropTypes.shape({
        errors: PropTypes.array,
      }),
      new_password: PropTypes.shape({
        children: PropTypes.shape({
          first: PropTypes.shape({
            errors: PropTypes.array,
          }),
        }),
      }),
      user: PropTypes.shape({
        children: PropTypes.shape({
          mobile: PropTypes.shape({
            errors: PropTypes.array,
          }),
        }),
      }),
    }),
  }),
  formValues: PropTypes.shape({
    company_email: PropTypes.string,
    confirm_password: PropTypes.string,
    demographic: PropTypes.string,
    first_name: PropTypes.string,
    has_read_terms_and_conditions: PropTypes.bool,
    home_zip: PropTypes.string,
    last_name: PropTypes.string,
    new_password: PropTypes.string,
  }),
  profile: PropTypes.shape({
    user_has_confirmed: PropTypes.any,
  }),
  setFormValues: PropTypes.func,
  user: PropTypes.shape({
    email: PropTypes.any,
    email_verified: PropTypes.any,
    has_set_password: PropTypes.any,
  }),
  setFormErrors: PropTypes.func,
};

export default Step1A;
