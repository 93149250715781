const Welcome = () => {
  return (
    <div className="text-center">
      <p className="onboarding-modal__bold-text">
        Best Money Moves is a financial wellness and education platform that helps you pinpoint areas of financial
        stress and learn key skills to make managing your personal finances easier.
      </p>
      <p className="onboarding-modal__bold-text">
        There are a few steps to get started and we&apos;ll guide you through them.{' '}
      </p>
      <p className="onboarding-modal__bold-text">It&apos;ll only take a few minutes!</p>
    </div>
  );
};

export default Welcome;
