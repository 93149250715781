import { useEffect, useState } from 'react';
import { FormGroup, Label, Input, Alert, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { QUESTION_TYPE_OPTIONS } from '../../routes/assessments.financial-wellness/constants';
import { questionShape } from '../../utils/shapes';
import { useFetcher } from '@remix-run/react';
import { STEP_3_START } from '../OnboardingModal/constants';

const Question = ({ questionNumber, question, formValues, setFormValues, isLastQuestion, setCanProceed, goToStep }) => {
  const [formErrors, setFormErrors] = useState(null);
  const fetcher = useFetcher({ key: 'onboarding' });

  const startOver = () => {
    setFormErrors(null);
    goToStep(STEP_3_START);
  };

  // Stash the form errors on the state. Since fetcher data persists,
  // we need a snapshot of those errors at the time of submission.
  useEffect(() => {
    // Only set the form errors on the last question, since that is when submission occurs.
    // This prevents the errors from immediately being reset when we clear them out by starting over.
    if (isLastQuestion) {
      setFormErrors(fetcher.data?.errors);
    }
  }, [fetcher.data?.errors, isLastQuestion]);

  useEffect(() => {
    setCanProceed && setCanProceed(formValues.financialWellness.answers[question.id] != null);
  }, [setCanProceed, formValues.financialWellness.answers, question.id]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormValues((prev) => ({
      ...prev,
      financialWellness: {
        ...prev.financialWellness,
        answers: {
          ...prev.financialWellness.answers,
          [question.id]: Number(value),
        },
      },
      [name]: Number(value),
    }));
  };

  const typeConfig = QUESTION_TYPE_OPTIONS[question.question_type] ?? { options: [], label: '' };

  return (
    <>
      {formErrors && (
        <Alert color="danger">
          {formErrors}
          <Button color="link" className="p-0 ps-2" onClick={startOver}>
            Return to Financial Assessment Start.
          </Button>
        </Alert>
      )}
      <p className="text-center">{typeConfig.label}</p>
      <p className="text-center font-weight-bold">
        {questionNumber}. {question.question}
      </p>
      <div className="d-flex justify-content-center">
        <FormGroup className="p-3">
          {typeConfig.options.map((opt, i) => {
            const value = i;
            return (
              <FormGroup key={opt} className="mb-1" check>
                <Label className="mb-1" check>
                  {opt}
                  <Input
                    name="financialWellnessAnswer"
                    type="radio"
                    value={value}
                    checked={formValues.financialWellness.answers[question.id] === value}
                    onChange={handleChange}
                    required
                  />
                </Label>
              </FormGroup>
            );
          })}
          <input type="hidden" name="financialWellnessQuestionId" value={question.id} />
          <input type="hidden" name="isLastQuestion" value={isLastQuestion} />
        </FormGroup>
      </div>
    </>
  );
};

Question.propTypes = {
  questionNumber: PropTypes.number,
  question: questionShape,
  formValues: PropTypes.shape({
    financialWellness: PropTypes.shape({
      answers: PropTypes.object,
    }),
  }),
  setFormValues: PropTypes.func,
  isLastQuestion: PropTypes.bool,
  setCanProceed: PropTypes.func,
  goToStep: PropTypes.func,
};

export default Question;
