import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

const ModalPageButton = ({ className, color, children, ...props }) => {
  return (
    <Button
      className={`pagination-button ${className} ${color === 'orange' ? 'orange' : ''}`}
      color={color !== 'orange' ? color : 'default'}
      {...props}
    >
      {children}
    </Button>
  );
};
ModalPageButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.func]),
  className: PropTypes.string,
  color: PropTypes.string,
};

export default ModalPageButton;
