const Step3Start = () => {
  return (
    <div className="text-center">
      <h2 className="mb-3">Now we&apos;re going to ask you 10 questions.</h2>
      <p>
        This will help us setup your dashboard so that we can give you more personalized content and recommendations.
        And you&apos;ll be able to see how your financial life compares to others.
      </p>
      <p>Click the Next button to start!</p>
    </div>
  );
};

export default Step3Start;
