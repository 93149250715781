import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { useFetcher, useRouteLoaderData, Link } from '@remix-run/react';
import Modal from '../Modal';
import ModalPageButton from '../ModalPageButton';
import { ModalBody, Button, Spinner } from 'reactstrap';
import Step1A from './Step1A';
import Step1B from './Step1B';
import Welcome from './Welcome';
import Step2 from './Step2';
import step3Questions from './Step3';
import Step3Start from './Step3Start';
import Step3End from './Step3End';
import Step4A from './Step4A';
import Step4B from './Step4B';
import Congratulations from './Congratulations';
import Privacy from './Privacy';
import TwoFactorAuth from './TwoFactorAuth';
import { VerifyProvider } from '../../contexts/verifyContext';
import { getErrorsFromFetcherData } from '../../utils/getErrorsFromFetcherData';
import { UPDATE_PROFILE, CREATE_STRESS_EVENTS } from '../../routes/_index/constants';
import {
  TWO_FACTOR_AUTH,
  WELCOME,
  PRIVACY,
  STEP_1A,
  STEP_1B,
  STEP_2A,
  STEP_2B,
  STEP_3_START,
  STEP_3_END,
  STEP_4A,
  STEP_4B,
  CONGRATULATIONS,
} from './constants';

const OnboardingModal = ({ isOpen, profile, onExit }) => {
  const fetcher = useFetcher({ key: 'onboarding' });
  const { user, company, financialWellness, stressEvents, hasCreditScoreAccess } = useRouteLoaderData('root');
  const { assessment, currentScore } = financialWellness || {};

  const pages = useMemo(() => {
    const pages = [
      !user.mobile_verified && !company.has_blind_single_sign_on
        ? {
            id: TWO_FACTOR_AUTH,
            component: TwoFactorAuth,
            header: <h2>Two Factor Authentication </h2>,
            intent: UPDATE_PROFILE,
          }
        : null,
      {
        id: WELCOME,
        component: Welcome,
        header: (
          <>
            <h2>Welcome to Best Money Moves Financial Wellness Program!</h2>
          </>
        ),
      },
      {
        id: PRIVACY,
        component: Privacy,
        header: (
          <>
            <h2>Our Privacy Promise</h2>
          </>
        ),
      },
      {
        id: STEP_1A,
        component: Step1A,
        header: (
          <div>
            <h2>Tell us about yourself!</h2>
            <p>
              We take your living situation and zip code into account when calculating your personalized budget
              recommendations.
            </p>
          </div>
        ),
      },
      {
        id: STEP_1B,
        component: Step1B,
        header: (
          <>
            <h2>Tell us about yourself!</h2>
            <p>
              We collect demographic information to learn more about how financial stress impacts users of different
              backgrounds and provide even more personalized insights. We keep this information private and only use it
              to improve our technology.
            </p>
          </>
        ),
        intent: UPDATE_PROFILE,
      },
      hasCreditScoreAccess
        ? {
            id: STEP_2A,
            component: Step2,
            header: (
              <>
                <h2>Get your free credit score!</h2>
                <p>
                  You can connect your free VantageScore 3.0 from Equifax to your Best Money Moves account. Once
                  enabled, your credit score will update every month and we&apos;ll offer insights to help explain why
                  your credit score is what it is. Checking your credit score using this tool will not impact your score
                  in any way.
                </p>
              </>
            ),
            options: { nextButton: { label: 'Skip', skipTo: STEP_3_START } },
          }
        : null,
      // This is duplicated with the previous step, but it's necessary to have it here to skip
      // to the next step and show confirmation of credit score.
      hasCreditScoreAccess
        ? {
            id: STEP_2B,
            component: Step2,
            header: (
              <>
                <h2>Credit Score!</h2>
              </>
            ),
          }
        : null,
      {
        id: STEP_3_START,
        component: Step3Start,
        options: { backButton: { backTo: hasCreditScoreAccess ? STEP_2A : STEP_1B } },
        header: (
          <>
            <h2>Quick Financial Wellness Score</h2>
            <p>
              Your financial wellness score provides a snapshot of your overall relationship with money. Answer 10 quick
              questions to receive a wellness score out of 100. This financial wellness assessment was designed by IBM
              for the Consumer Financial Protection Bureau
              {''}{' '}
              <Link to="https://www.consumerfinance.gov/" target="_blank" rel="noreferrer">
                CFPB.gov
              </Link>
            </p>
          </>
        ),
      },
      ...(step3Questions(assessment) ?? []),
      {
        id: STEP_3_END,
        component: Step3End,
        header: (
          <>
            <h2>Quick Financial Wellness Score</h2>
          </>
        ),
      },
      {
        id: STEP_4A,
        component: Step4A,
        header: (
          <>
            <h2>Tell Us About Your Financial Stress</h2>
          </>
        ),
      },
      {
        id: STEP_4B,
        component: Step4B,
        header: (
          <>
            <h2>Financial Stress</h2>
            <p>
              Now, we&apos;re going to gauge your financial stress so that we can pinpoint areas for improvement. Select
              three categories of financial stress that are currently causing issues. Best Money Moves will register
              your financial stress scores and use them to recommended personalized information, tools and solutions.
            </p>
          </>
        ),
        intent: CREATE_STRESS_EVENTS,
      },
      {
        id: CONGRATULATIONS,
        component: Congratulations,
        header: (
          <>
            <h2>Congratulations</h2>
          </>
        ),
        intent: UPDATE_PROFILE,
        options: { nextButton: { label: 'Save' } },
      },
    ];

    return pages.filter((page) => page !== null);
  }, [assessment, user.mobile_verified, hasCreditScoreAccess, company.has_blind_single_sign_on]);

  // Check user data to confirm the user has actually finished onboarding.
  // If not, restart onboarding at the appropriate step.
  let initialPage = 0;
  if (user?.profile_confirmed) {
    if (!currentScore) {
      initialPage = pages.findIndex((page) => page.id === STEP_3_START);
    } else if (stressEvents.length === 0) {
      initialPage = pages.findIndex((page) => page.id === STEP_4A);
    }
  }

  const [pageIndex, setPageIndex] = useState(initialPage);
  const [nextDisabled, setNextDisabled] = useState(false);
  const [formValues, setFormValues] = useState({
    // Financial wellness score
    financialWellness: {
      currentScore: currentScore,
      questions: assessment?.questions ?? [],
      answers: {},
    },
    // Financial Stress
    stressEvents: [],
    // Profile
    first_name: profile.first_name,
    last_name: profile.last_name,
    company_email: user.email,
    mobile: user.mobile,
    home_zip: profile.home_zip,
    demographic: profile.demographic,
    gender: profile.gender,
    marital_status: profile.marital_status,
    age_range: profile.age_range,
    household_income: profile.household_income,
    number_in_household: profile.number_in_household,
    has_read_terms_and_conditions: profile.has_read_terms_and_conditions,
  });

  const goToStep = (pageId) => setPageIndex(pages.findIndex((page) => page.id === pageId));

  // Stash the form errors on the state. Since fetcher data persists,
  // we need a snapshot of those errors at the time of submission.
  const [formErrors, setFormErrors] = useState(null);
  useEffect(() => setFormErrors(getErrorsFromFetcherData(fetcher.data?.errors)), [fetcher.data?.errors]);

  // Since we're only rendering the active step component, OnboardingModal needs to know
  // which step contains which fields in order to determine which step to show
  // in the event of an error.
  useEffect(() => {
    const step1aFields = ['first_name', 'last_name', 'company_email', 'home_zip', 'demographic'];

    const errorKeys = Object.keys(formErrors?.children || {});

    if (step1aFields.some((field) => errorKeys.includes(field))) {
      setPageIndex(pages.findIndex((page) => page.id === STEP_1A));
    }

    // The mobile field is now set on the TWO_FACTOR_AUTH step instead of STEP_1A.
    if (errorKeys.includes('user')) {
      setPageIndex(pages.findIndex((page) => page.id === TWO_FACTOR_AUTH));
    }
  }, [formErrors?.children, pages]);

  const handleBack = () => {
    setNextDisabled(false);

    if (pages[pageIndex].options?.backButton?.backTo) {
      setPageIndex(pages.findIndex((page) => page.id === pages[pageIndex].options.backButton.backTo));
    } else {
      setPageIndex((prev) => (prev === 0 ? prev : prev - 1));
    }
  };

  // Change page when there is no intent or if updating profile
  const handleNext = (e) => {
    const currentPage = pages[pageIndex];
    const isLastPage = pageIndex === pages.length - 1;

    if (
      currentPage?.intent &&
      currentPage?.intent !== UPDATE_PROFILE &&
      !currentPage?.options?.nextButton?.skipTo &&
      !isLastPage
    ) {
      return;
    }

    e?.preventDefault();

    // Go to skipTo option or next page
    if (currentPage?.options?.nextButton?.skipTo && e) {
      setPageIndex(pages.findIndex((page) => page.id === pages[pageIndex]?.options?.nextButton?.skipTo));
    } else if (!currentPage.intent && !isLastPage) {
      setPageIndex(pageIndex + 1);
    }

    // Update Profile submits two pages of form data.
    // We need to gather it all here and post manually.
    if (currentPage.intent === UPDATE_PROFILE) {
      let profileData = { ...formValues, has_completed_onboarding: profile.has_completed_onboarding };

      // If on the last page, update the users profile to complete onboarding,
      // and remove lingering password data, since that has already changed.
      if (isLastPage) {
        profileData.has_completed_onboarding = true;
        delete profileData.new_password;
        delete profileData.confirm_password;
      }

      fetcher.submit(
        {
          ...profileData,
          intent: UPDATE_PROFILE,
          pageIndex,
        },
        { method: 'POST', action: '?index' }
      );
    }
  };

  // Change page based on page index in response
  useEffect(() => {
    if (fetcher.state === 'idle' && fetcher.data?.status === 'OK') {
      const previousPageIndex = fetcher.data.pageIndex;

      if (Number(previousPageIndex) !== pages.length - 1) {
        setPageIndex(Number(previousPageIndex) + 1);
      }
    }
  }, [fetcher, pages.length]);

  const Page = pages[pageIndex].component;

  const nextButton = pages[pageIndex]?.options?.nextButton || { label: 'Next' };

  return (
    <Modal isOpen={isOpen} centered size="lg" className="onboarding-modal" backdrop="static">
      <div className="text-end">
        <button className="btn-close" onClick={onExit} />
        <div className="text-center mb-5">{pages[pageIndex].header}</div>
      </div>
      <ModalBody>
        <fetcher.Form method="post" action="?index">
          <VerifyProvider userId={user.id}>
            <Page
              formValues={formValues}
              fetcherData={fetcher.data}
              setFormValues={setFormValues}
              canProceed={(canProceed) => setNextDisabled(!canProceed)}
              profile={profile}
              fetcher={fetcher}
              user={user}
              formErrors={formErrors}
              setFormErrors={setFormErrors}
              handleNext={handleNext}
              goToStep={goToStep}
            />
          </VerifyProvider>
          <input hidden readOnly name="pageIndex" value={pageIndex} />

          <div className="d-flex justify-content-between">
            <ModalPageButton onClick={handleBack} disabled={pageIndex === 0}>
              Back
            </ModalPageButton>
            <div>
              {/* Allow devs to skip forward */}
              {process.env.NODE_ENV === 'development' && (
                <Button
                  color="link"
                  onClick={() => {
                    setPageIndex(pageIndex + 1);
                    setNextDisabled(false);
                  }}
                >
                  Dev Skip
                </Button>
              )}
              <ModalPageButton
                type="submit"
                onClick={handleNext}
                name="intent"
                value={pages[pageIndex].intent ?? ''}
                color="primary"
                disabled={nextDisabled || fetcher.state !== 'idle'}
              >
                {fetcher.state !== 'idle' && <Spinner size="sm" className="me-2" />}
                {nextButton?.label}
              </ModalPageButton>
            </div>
          </div>
        </fetcher.Form>
      </ModalBody>
    </Modal>
  );
};

OnboardingModal.propTypes = {
  isOpen: PropTypes.bool,
  profile: PropTypes.shape({
    age_range: PropTypes.string,
    demographic: PropTypes.string,
    first_name: PropTypes.string,
    gender: PropTypes.string,
    home_zip: PropTypes.string,
    household_income: PropTypes.string,
    last_name: PropTypes.string,
    marital_status: PropTypes.string,
    number_in_household: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  onExit: PropTypes.func,
};

export default OnboardingModal;
