const Privacy = () => {
  return (
    <div className="text-center">
      <p className="onboarding-modal__bold-text">
        All information in your account is private. And, by that we mean, we&apos;ll never share what you enter with
        anyone. And we mean never! We keep your information strictly confidential.{' '}
      </p>
    </div>
  );
};

export default Privacy;
