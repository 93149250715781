import PropTypes from 'prop-types';
import { FormGroup, Input, Container, Row, Col } from 'reactstrap';
import { stressEventShape } from '../../utils/shapes';
import './onboarding.css';

const Step4B = ({ formValues, setFormValues }) => {
  const changeLevel = (level, type) => {
    setFormValues((prev) => ({
      ...prev,
      stressEvents: prev.stressEvents.map((stressEvent) =>
        stressEvent.type === type ? { ...stressEvent, level: level } : stressEvent
      ),
    }));
  };

  return (
    <>
      <div className="text-center font-weight-light mb-lg-1 mb-md-5 mb-sm-3 mb-4 h3">
        <span>Rate each stress from </span>
        <br className={'d-lg-none'} />
        <span>1 (low) to 10 (high)</span>
      </div>
      <Container className="p-xs-2 p-lg-5">
        <Row>
          <Col lg={5} />
          <Col lg={7}>
            <div className="d-flex d-none d-lg-flex">
              {new Array(10).fill('').map((_, i) => (
                <span key={`stress-level-${i + 1}`} className="text-center" style={{ flex: '1' }}>
                  {i + 1}
                </span>
              ))}
            </div>
          </Col>
        </Row>
        {formValues.stressEvents.map((stressEvent) => {
          return (
            <>
              <Row key={stressEvent.type} className={'d-none d-lg-flex'}>
                <Col xs={5}>
                  <p>{stressEvent.description}</p>
                </Col>
                <Col xs={7}>
                  <FormGroup className="px-2">
                    <Input
                      name="level[]"
                      type="range"
                      min="1"
                      max="10"
                      step="1"
                      value={stressEvent.level}
                      onChange={(e) => changeLevel(Number(e.target.value), stressEvent.type)}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <div key={stressEvent.type} className={'d-xs-flex d-lg-none'}>
                <Row>
                  <p>{stressEvent.description}</p>
                </Row>
                <div>
                  <Row className="d-flex d-xs-flex d-lg-none justify-content-between align-items-baseline mb-3 number-selector-row">
                    {new Array(10).fill('').map((_, i) => (
                      <Col
                        key={`stress-level-${i + 1}`}
                        className={
                          Number(stressEvent.level) === i + 1 ? 'selected-stress-number' : 'unselected-stress-number'
                        }
                      >
                        {i + 1}
                      </Col>
                    ))}
                  </Row>
                  <Row>
                    <FormGroup className="mb-lg-3">
                      <Input
                        name="level[]"
                        type="range"
                        min="1"
                        max="10"
                        step="1"
                        value={stressEvent.level}
                        onChange={(e) => changeLevel(Number(e.target.value), stressEvent.type)}
                      />
                      <input hidden readOnly name="type[]" value={stressEvent.type} />
                      <input hidden readOnly name="description[]" value={stressEvent.description} />
                    </FormGroup>
                  </Row>
                </div>
              </div>
            </>
          );
        })}
        <Row>
          <Col>
            <p className="text-center">
              Slide the markers to indicate how stressed you are about each item, where 1 is not stressed and 10 is very
              stressed.
            </p>
          </Col>
        </Row>
      </Container>
    </>
  );
};

Step4B.propTypes = {
  formValues: PropTypes.shape({
    stressEvents: PropTypes.arrayOf(stressEventShape),
  }),
  setFormValues: PropTypes.func,
};

export default Step4B;
