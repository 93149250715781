import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, useSearchParams, useNavigate, useNavigation } from '@remix-run/react';
import {
  Navbar as BootstrapNavbar,
  NavbarBrand,
  InputGroup,
  Input,
  Button,
  NavbarToggler,
  Collapse,
  Spinner,
} from 'reactstrap';
import LeftMenu from './LeftMenu';
import DefaultLogo from '../assets/images/bmm-logo-color.png';
import { PORTRAIT, LANDSCAPE } from '../utils/constants';

export function Navbar({ companyLogoURL, pageName, orientation }) {
  const [searchParams] = useSearchParams();
  const textParam = searchParams.get('text') ?? '';

  const [inputValue, setInputValue] = useState(textParam);
  const [isOpen, setIsOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const navigation = useNavigation();
  const navigate = useNavigate();
  const toggle = () => setIsOpen(!isOpen);

  useEffect(() => {
    setInputValue(decodeURIComponent(textParam));
  }, [textParam]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const params = searchParams;
    params.set('text', encodeURIComponent(inputValue));
    navigate(`/learn/read?${params.toString()}`);
  };

  // Stop spinner once idle
  useEffect(() => {
    if (navigation.state === 'idle') {
      setIsSubmitting(false);
    }
  }, [navigation]);

  let orientationClass = '';

  if (orientation === PORTRAIT) {
    orientationClass = 'sticky-top';
  }

  if (orientation === LANDSCAPE) {
    orientationClass = 'fixed-top';
  }

  return (
    <BootstrapNavbar className={`main-navbar ${orientationClass}`} container="lg" expand="md">
      <NavbarBrand href="/" className={`p-0 ${orientation}-wrapper`}>
        <img alt="logo" src={companyLogoURL ?? DefaultLogo} className="landscape-logo" />
      </NavbarBrand>
      <h1 className="d-none d-sm-block d-md-none">{pageName}</h1>
      <NavbarToggler onClick={toggle} />
      <h1 className="d-none d-sm-none d-md-block">{pageName}</h1>
      <Collapse isOpen={isOpen} navbar>
        <Form className="d-flex my-2 my-sm-0" onSubmit={handleSubmit}>
          <InputGroup className="main-search-input">
            <Input value={inputValue} onChange={(e) => setInputValue(e.target.value)} name="text" />
            <Button color="primary">{isSubmitting ? <Spinner size="sm" /> : 'Search'}</Button>
          </InputGroup>
        </Form>
        <div className="d-md-none navbar-nav-scroll">
          <LeftMenu showSubroutes={true} toggle={toggle} isOpen={isOpen} />
        </div>
      </Collapse>
    </BootstrapNavbar>
  );
}

Navbar.propTypes = {
  companyLogoURL: PropTypes.string,
  includeLogo: PropTypes.bool,
  orientation: PropTypes.string,
  pageName: PropTypes.string,
};

export default Navbar;
