import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label, Input, Container, Row, Col, Alert } from 'reactstrap';
import { useRouteData } from '../../hooks/useRouteData/useRouteData';
import { stressEventShape } from '../../utils/shapes';

const Step4A = ({ formValues, setFormValues, canProceed }) => {
  const { stressTypes } = useRouteData('routes/_index');
  const [error, setError] = useState('');

  const numOfRows = Math.round(stressTypes.length / 3);

  const handleChange = (e) => {
    const stressType = stressTypes.find((type) => type.id === Number(e.target.value));
    if (e.target.checked) {
      if (formValues.stressEvents.length < 3) {
        const stressEvent = {
          level: '1',
          type: stressType.id,
          description: stressType.name,
        };

        setFormValues((prev) => ({
          ...prev,
          stressEvents: [...prev.stressEvents, stressEvent],
        }));
        setError('');
      } else {
        setError('You can only select up to 3 stress types.');
      }
    } else {
      setFormValues((prev) => ({
        ...prev,
        stressEvents: prev.stressEvents.filter((stressEvent) => stressEvent.type !== Number(e.target.value)),
      }));
      setError('');
    }
  };

  useEffect(() => {
    if (formValues.stressEvents.length === 0) {
      canProceed(false);
    } else {
      setError('');
      canProceed(formValues.stressEvents.length <= 3);
    }
  }, [formValues.stressEvents, canProceed]);

  return (
    <>
      <h3 className="text-primary font-weight-light text-center mb-4">
        Check 3 things that stress you out about money.
      </h3>
      {error && <Alert color="danger">{error}</Alert>}
      <Container className="mb-5">
        <Row>
          {Array.from({ length: 3 }).map((_, i) => {
            const startIndex = i * numOfRows;
            const endIndex = startIndex + 5;

            return (
              <Col key={`column-${i}`}>
                {stressTypes.slice(startIndex, endIndex).map((stressType) => {
                  if (stressType?.name === 'Add Your Own Stress') {
                    return null;
                  }

                  return (
                    <FormGroup check key={stressType.id}>
                      <Input
                        name="financialStress"
                        type="checkbox"
                        value={stressType.id}
                        checked={formValues.stressEvents.some((event) => event.type === stressType.id)}
                        onChange={handleChange}
                      />
                      <Label check>{stressType.name}</Label>
                    </FormGroup>
                  );
                })}
              </Col>
            );
          })}
        </Row>
      </Container>
    </>
  );
};

Step4A.propTypes = {
  formValues: PropTypes.shape({
    stressEvents: PropTypes.arrayOf(stressEventShape),
  }),
  setFormValues: PropTypes.func,
  canProceed: PropTypes.func.isRequired,
};

export default Step4A;
