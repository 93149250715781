import { useEffect, useContext } from 'react';
import React from 'react';
import { FormGroup, Label, Input, FormFeedback, Button, FormText, Row, Col } from 'reactstrap';
import PhoneInput from 'react-phone-number-input/input';
import {
  VerifyContext,
  VerifyDispatchContext,
  setShowMobileValidationForm,
  submitMobileValidationCode,
  setMobileVerificationCode,
  verifyMobile,
} from '../../contexts/verifyContext';
import RequestMobileCodeForm from './RequestMobileCodeForm';
import './onboarding.css';

const TwoFactorAuth = ({ formValues, setFormValues, canProceed, formErrors, user, setFormErrors }) => {
  const verifyState = useContext(VerifyContext);
  const verifyDispatch = useContext(VerifyDispatchContext);

  const {
    showMobileValidationForm,
    showMobileCodeForm,
    verifyPhoneFetcher,
    verifyPhoneCheckFetcher,
    mobileVerificationCode,
  } = verifyState;

  // Either use the values from the user object, or the values from the final verify response.
  const confirmedMobileValue =
    (user.mobile_verified ? user.mobile : null) || verifyPhoneCheckFetcher.data?.confirmedPhone || null;

  const handleMobileChange = (phone) => {
    verifyDispatch(setShowMobileValidationForm(!!phone && phone !== confirmedMobileValue));
    setFormValues((prev) => ({ ...prev, mobile: phone }));

    // If the previous value had errors, clear them since the value is changing.
    const hasErrors = formErrors?.children?.user?.children?.mobile?.errors?.length > 0;
    if (hasErrors) {
      setFormErrors((prev) => {
        prev.children.user.children.mobile.errors = [];

        return prev;
      });
    }
  };

  const handleMobileCodeChanged = (e) => verifyDispatch(setMobileVerificationCode(e.target.value));
  const handleRequestMobileCode = () => verifyDispatch(verifyMobile(formValues.mobile, verifyPhoneFetcher));

  const isNewMobileVerified =
    verifyPhoneCheckFetcher.data?.success && !!formValues.mobile && formValues.mobile === confirmedMobileValue;

  useEffect(() => {
    canProceed(isNewMobileVerified);
  }, [canProceed, isNewMobileVerified]);

  const isPhoneInvalid =
    typeof formValues.mobile !== 'undefined' &&
    (!formValues.mobile ||
      !formValues.mobile.match(/^\+1\d{10}$/) ||
      (formErrors?.children?.user?.children?.mobile?.errors || []).length > 0);

  return (
    <Row>
      <Col xs={12} sm={{ offset: 2, size: 8 }} className="text-center">
        <p>
          To ensure the security of your account, Two-Factor Authentication (2FA) is required to use this app. Please
          enter a valid phone number and confirm it before continuing.
        </p>
        <p>
          This extra layer of security helps protect your account by requiring a second form of verification. Without
          completing this step, you won&apos;t be able to proceed.
        </p>
      </Col>
      <Col xs={12} sm={{ offset: 3, size: 6 }}>
        <FormGroup className="mobile-form-group">
          <span>
            <Label for="mobile">Mobile</Label>
            <RequestMobileCodeForm
              showPhoneValidationForm={showMobileValidationForm}
              user={user}
              mobile={formValues.mobile}
              confirmedMobileValue={confirmedMobileValue}
              isPhoneInvalid={
                typeof formValues.mobile !== 'undefined' &&
                (!formValues.mobile || !formValues.mobile.match(/^\+1\d{10}$/))
              }
              verifyPhone={() => verifyDispatch(verifyMobile(formValues.mobile, verifyPhoneFetcher))}
              isMobileVerified={isNewMobileVerified}
            />
          </span>
          <PhoneInput
            id="mobile"
            name="mobile"
            country="US"
            className={['form-control', isPhoneInvalid ? 'is-invalid' : ''].join(' ')}
            value={formValues.mobile || ''}
            onChange={handleMobileChange}
          />
          <FormFeedback>
            This is not a valid phone number! {formErrors?.children?.user?.children?.mobile?.errors?.join(' ')}
          </FormFeedback>
          <FormText>
            {!verifyPhoneCheckFetcher.data?.success &&
              verifyPhoneFetcher.data?.success &&
              verifyPhoneFetcher.data?.message}
          </FormText>
        </FormGroup>
      </Col>
      {showMobileCodeForm && !verifyPhoneCheckFetcher.data?.success && (
        <Col xs={12} sm={{ offset: 3, size: 6 }}>
          <Row>
            <Col xs={8}>
              <FormGroup>
                <span>
                  <Label for="mobileVerificationCode">Enter code</Label>
                  <Button size="sm" color="link" className="pt-0 pb-0 ps-2 pe-0" onClick={handleRequestMobileCode}>
                    Resend Code
                  </Button>
                </span>
                <Input
                  id="mobileVerificationCode"
                  name="mobileVerificationCode"
                  type="text"
                  onChange={handleMobileCodeChanged}
                  required
                  placeholder="8-digit code"
                  invalid={
                    !!mobileVerificationCode && verifyPhoneCheckFetcher.data && !verifyPhoneCheckFetcher.data?.success
                  }
                />
                <FormFeedback>This is not a valid code!</FormFeedback>
              </FormGroup>
            </Col>
            <Col xs={4} className="d-flex align-items-end">
              <FormGroup>
                <Button
                  size="sm"
                  color="primary"
                  className="ps-3 pe-3"
                  onClick={() =>
                    submitMobileValidationCode(formValues.mobile, mobileVerificationCode, verifyPhoneCheckFetcher)
                  }
                  disabled={
                    !mobileVerificationCode?.trim() ||
                    verifyPhoneCheckFetcher.state === 'loading' ||
                    verifyPhoneCheckFetcher.state === 'submitting'
                  }
                >
                  Submit
                </Button>
              </FormGroup>
            </Col>
          </Row>
        </Col>
      )}
    </Row>
  );
};

export default TwoFactorAuth;
