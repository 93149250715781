// Step IDs. Helpful if we need to programmatically navigate to a specific step.
// Assessment question IDs are set in Step3.
export const TWO_FACTOR_AUTH = 'TWO_FACTOR_AUTH';
export const WELCOME = 'WELCOME';
export const PRIVACY = 'PRIVACY';
export const STEP_1A = 'STEP_1A';
export const STEP_1B = 'STEP_1B';
export const STEP_2A = 'STEP_2A';
export const STEP_2B = 'STEP_2B';
export const STEP_3_START = 'STEP_3_START';
export const STEP_3_END = 'STEP_3_END';
export const STEP_4A = 'STEP_4A';
export const STEP_4B = 'STEP_4B';
export const CONGRATULATIONS = 'CONGRATULATIONS';
