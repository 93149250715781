import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { FormGroup, Label, Input, Container, Row, Col, Alert, FormFeedback } from 'reactstrap';
import {
  SINGLE,
  MARRIED,
  DIVORCED,
  SEPARATED,
  WIDOWED,
  MALE,
  FEMALE,
  NON_BINARY,
  INCOME_OPTIONS,
  AGE_RANGE_OPTIONS,
} from '../../utils/constants';
import './onboarding.css';

const Step1B = ({ formValues, setFormValues, canProceed, fetcherData }) => {
  const handleChange = (e) => {
    setFormValues((prev) => ({
      ...prev,
      [e.target.name]: e.target.value?.trim(),
    }));
  };

  useEffect(() => {
    const { gender, marital_status, age_range, household_income, number_in_household } = formValues;

    canProceed(gender && marital_status && age_range && household_income && number_in_household);
  }, [canProceed, formValues]);

  return (
    <Container>
      {fetcherData?.errors?.errors?.length > 0 && (
        <Alert color="danger">
          <ul>
            {fetcherData.errors.errors.map((err, i) => (
              <li key={`${err}-${i}`}>{err}</li>
            ))}
          </ul>
        </Alert>
      )}
      <Row>
        <Col xs={12} lg={true}>
          <Label className="all-caps-grey-label" for="gender">
            Gender
          </Label>
          <FormGroup tag="fieldset" className="bg-grey p-3">
            <FormGroup check>
              <Input
                id={MALE}
                name="gender"
                type="radio"
                value={MALE}
                checked={formValues.gender === MALE}
                onChange={handleChange}
              />
              <Label for={MALE} check>
                Male
              </Label>
            </FormGroup>
            <FormGroup check>
              <Input
                id={FEMALE}
                name="gender"
                type="radio"
                value={FEMALE}
                checked={formValues.gender === FEMALE}
                onChange={handleChange}
              />
              <Label for={FEMALE} check>
                Female
              </Label>
            </FormGroup>
            <FormGroup check>
              <Input
                id={NON_BINARY}
                name="gender"
                type="radio"
                value={NON_BINARY}
                checked={formValues.gender === NON_BINARY}
                onChange={handleChange}
              />
              <Label for={NON_BINARY} check>
                Nonbinary
              </Label>
            </FormGroup>
          </FormGroup>
        </Col>
        <Col className={'d-flex marital-status-column'}>
          <Col>
            <Label className="all-caps-grey-label marital-status-label" for="marital_status">
              Marital Status
            </Label>
            <FormGroup className="p-sm-3 marital-status-form-group">
              <FormGroup check>
                <Input
                  id="single"
                  name="marital_status"
                  type="radio"
                  value={SINGLE}
                  checked={formValues.marital_status === SINGLE}
                  onChange={handleChange}
                />
                <Label for="single" check>
                  Single
                </Label>
              </FormGroup>
              <FormGroup check>
                <Input
                  id="married"
                  name="marital_status"
                  type="radio"
                  value={MARRIED}
                  checked={formValues.marital_status === MARRIED}
                  onChange={handleChange}
                />
                <Label for="married" check>
                  Married
                </Label>
              </FormGroup>
              <FormGroup check>
                <Input
                  id="separated"
                  name="marital_status"
                  type="radio"
                  value={SEPARATED}
                  checked={formValues.marital_status === SEPARATED}
                  onChange={handleChange}
                />
                <Label for="separated" check>
                  Separated
                </Label>
              </FormGroup>
            </FormGroup>
          </Col>
          <Col className={'col d-flex align-items-center'}>
            <FormGroup className="mb-xs-5 mb-sm-0 p-sm-3 marital-status-form-group">
              <FormGroup check>
                <Input
                  id="widowed"
                  name="marital_status"
                  type="radio"
                  value={WIDOWED}
                  checked={formValues.marital_status === WIDOWED}
                  onChange={handleChange}
                />
                <Label for="widowed" check>
                  Widowed
                </Label>
              </FormGroup>
              <FormGroup check>
                <Input
                  id="divorced"
                  name="marital_status"
                  type="radio"
                  value={DIVORCED}
                  checked={formValues.marital_status === DIVORCED}
                  onChange={handleChange}
                />
                <Label for="divorced" check>
                  Divorced
                </Label>
              </FormGroup>
            </FormGroup>
          </Col>
        </Col>
      </Row>
      <Row>
        <Col className={'col-12 col-sm mt-3 mt-sm-0'}>
          <Label className="all-caps-grey-label" for="age_range">
            Age
          </Label>
          <FormGroup className="bg-grey p-3">
            {Object.keys(AGE_RANGE_OPTIONS).map((ageRange) => (
              <FormGroup key={ageRange} check>
                <Input
                  id={ageRange}
                  name="age_range"
                  type="radio"
                  value={AGE_RANGE_OPTIONS[ageRange]}
                  checked={formValues.age_range === AGE_RANGE_OPTIONS[ageRange]}
                  onChange={handleChange}
                />
                <Label for={ageRange} check>
                  {ageRange}
                </Label>
              </FormGroup>
            ))}
          </FormGroup>
        </Col>
        <Col className={'col-12 col-sm'}>
          <Label className="all-caps-grey-label" for="household_income">
            Income
          </Label>
          <FormGroup className="p-3">
            {Object.keys(INCOME_OPTIONS).map((incomeRange) => (
              <FormGroup key={incomeRange} check>
                <Input
                  id={incomeRange}
                  name="household_income"
                  type="radio"
                  value={INCOME_OPTIONS[incomeRange]}
                  checked={formValues.household_income === INCOME_OPTIONS[incomeRange]}
                  onChange={handleChange}
                />
                <Label for={incomeRange} check>
                  {incomeRange}
                </Label>
              </FormGroup>
            ))}
          </FormGroup>
        </Col>
        <Col>
          <Label className="all-caps-grey-label" for="number_in_household">
            # In Household
          </Label>
          <FormGroup className="p-3">
            {['1', '2', '3', '4', '5'].map((num) => (
              <FormGroup key={num} check>
                <Input
                  id={num}
                  name="number_in_household"
                  type="radio"
                  value={num}
                  checked={formValues.number_in_household?.toString() === num}
                  onChange={handleChange}
                />
                <Label for={num} check>
                  {num === '5' ? '5+' : num}
                </Label>
              </FormGroup>
            ))}
          </FormGroup>
        </Col>
      </Row>
    </Container>
  );
};

Step1B.propTypes = {
  canProceed: PropTypes.func,
  fetcherData: PropTypes.shape({
    errors: PropTypes.shape({
      errors: PropTypes.array,
    }),
  }),
  formValues: PropTypes.shape({
    age_range: PropTypes.string,
    gender: PropTypes.string,
    household_income: PropTypes.string,
    marital_status: PropTypes.string,
    number_in_household: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  setFormValues: PropTypes.func,
};

export default Step1B;
