import { captureRemixErrorBoundaryError, withSentry } from '@sentry/remix';
import { useState, useEffect } from 'react';
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  useMatches,
  useLocation,
  useLoaderData,
  useRouteError,
  useNavigate,
  useNavigation,
  ScrollRestoration,
  useFetcher,
} from '@remix-run/react';
import { json } from '@remix-run/node';
import { ExternalScripts } from 'remix-utils/external-scripts';
import { Container, Row, Col } from 'reactstrap';
import mergeHeaders from 'merge-headers';
import { ToastContainer, toast as notify } from 'react-toastify';
import { getToast } from 'remix-toast';
import { config as fontAwesomeConfig } from '@fortawesome/fontawesome-svg-core';
import { FinancialWellnessService } from './services/assessments/financialWellness.service.js';
import { StressEventService } from './services/stressEvent.service.js';
import { CompanyService } from './services/company.service.js';
import Navbar from './components/Navbar.jsx';
import OnboardingModal from './components/OnboardingModal/OnboardingModal';
import LeftMenu from './components/LeftMenu';
import Footer from './components/Footer.jsx';
import PointsNotifier from './components/Notifier/PointsNotifier.jsx';
import { commitSession } from './sessions';
import { requireUserSession } from './utils/requireUserSession';
import { PORTRAIT, LANDSCAPE } from './utils/constants.js';
import { getBackendImageUrl } from './utils/functions';
import { getCompanyColorsCSS } from './utils/companyColors.js';
import bootstrap from '../app/style/bootstrap.scss?url';
import root from './root.css?url';
import 'react-toastify/dist/ReactToastify.css';
import fontawesomeStyle from '@fortawesome/fontawesome-svg-core/styles.css?url';
import kjeStyle from '../public/assets/dinky/KJE.css?url';
import kjeSiteSpecificStyle from '../public/assets/dinky/KJESiteSpecific.css?url';
import EmitterSingleton from './services/emitter.js';

fontAwesomeConfig.autoAddCss = false;

export const links = () => [
  { rel: 'stylesheet', href: bootstrap },
  { rel: 'stylesheet', href: root },
  { rel: 'stylesheet', href: kjeStyle },
  { rel: 'stylesheet', href: kjeSiteSpecificStyle },
  { rel: 'stylesheet', href: 'https://fonts.googleapis.com/css?family=Oxygen:300,700' },
  { rel: 'stylesheet', href: 'https://use.typekit.net/tjo4jkj.css' },
  { rel: 'stylesheet', href: fontawesomeStyle },
  { rel: 'icon', href: 'data:image/x-icon;base64,AA' },
  { rel: 'icon', href: '/icon.ico', type: 'image/x-icon' },
];

export const handle = {
  companyColors: (match) => {
    const company = match.data?.company;
    return company ? getCompanyColorsCSS(company) : '';
  },
};

export async function loader({ request }) {
  const ENV = {
    ENV: process.env.NODE_ENV,
    ENVIRONMENT: process.env.ENVIRONMENT,
    V2_API: process.env.V2_API,
    NEXUS_API_KEY: process.env.NEXUS_API_KEY,
    PAYITOFF_SCRIPT_URL: process.env.PAYITOFF_SCRIPT_URL,
    RECAPTCHA_SITEKEY: process.env.REACT_APP_RECAPTCHA_SITE_KEY,
    UPLOADS_CDN_URL_PREFIX: process.env.UPLOADS_CDN_URL_PREFIX,
    GA_MEASUREMENT_ID: process.env.GA_MEASUREMENT_ID,
  };

  const { toast, headers: toastHeaders } = await getToast(request);

  // This loader runs for all pages, and we don't want to request any data on the login page.
  if (['/login', '/register'].includes(new URL(request.url).pathname)) {
    return json(
      { ENV, toast },
      {
        headers: toastHeaders,
      }
    );
  }

  const { session, token, authorizedFetch } = await requireUserSession(request);

  const user = session.get('user');
  const profile = session.get('profile');
  const companyService = new CompanyService(token);
  // Load assessments data if the user does not have a current financial wellness score.
  const assessmentService = new FinancialWellnessService(token);

  const [stressEvents, company, currentFinancialWellnessScore, financialWellnessAssessment, userGroup] =
    await Promise.all([
      new StressEventService(token).getCurrentStressEvents(profile.id),
      companyService.getFromProfile(profile),
      assessmentService.getCurrentScore(),
      assessmentService.getAssessment(),
      companyService.getUserGroupFromProfile(profile),
    ]);

  const companyLogoURL = company.logo ? getBackendImageUrl(company.logo_relative_path) : null;

  const hasCreditScoreAccess = company?.has_credit_score && userGroup?.credit_score_access;
  const hasMoneyCoachAccess = company?.has_money_coach && userGroup?.money_coach_access;

  // TODO: Redirect to account page and require it to be filled if !user.profile_confirmed but partner_managed = true.
  // const requiresOnboarding = true;
  const requiresOnboarding = !profile.has_completed_onboarding;

  //Load recaptcha key
  const recaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

  // Load custom pages for the user's company
  const customPagesResponse = await authorizedFetch(`${process.env.V2_API}/api/customPages`);
  const customPages = await customPagesResponse.json();

  // Transform points toasts.
  let toasts = EmitterSingleton.getInstance().getMessages(token);
  toasts = toasts.length > 0 ? toasts.map((points) => ({ data: points })) : [];

  // If we have a toast from remix-toast (not used currently), add it to the array.
  if (toast) {
    toasts.push(toast);
  }

  return json(
    {
      hasCreditScoreAccess,
      hasMoneyCoachAccess,
      toast: toasts,
      articles: [],
      user,
      profile,
      company,
      companyLogoURL,
      recaptchaSiteKey,
      requiresOnboarding,
      financialWellness: {
        assessment: financialWellnessAssessment,
        currentScore: currentFinancialWellnessScore,
      },
      customPages,
      ENV,
      stressEvents,
    },
    {
      headers: mergeHeaders(
        {
          'Set-Cookie': await commitSession(session),
        },
        toastHeaders
      ),
    }
  );
}

function App() {
  const [domLoaded, setDomLoaded] = useState(false);
  const [previousPage, setPreviousPage] = useState('/');
  const location = useLocation();
  const navigation = useNavigation();
  const { profile, company, companyLogoURL, requiresOnboarding, customPages, ENV, toast } = useLoaderData() ?? {};
  const isAuthRoute = useMatches().some((match) => match.id.indexOf('/_auth') > -1);
  const [modalOpen, setModalOpen] = useState(requiresOnboarding);
  const matches = useMatches();
  const fetcher = useFetcher();

  const navigate = useNavigate();
  const potentialRedirect = location.pathname === '/' && location.hash.startsWith('#/');

  const page = location.pathname.split('/')[1];

  const handleOnExit = () => {
    setModalOpen(false);
    navigate('/logout');
  };

  let pageName = page
    ? page
        .split('-')
        .map((word) => word.charAt(0)?.toUpperCase() + word.slice(1))
        .join(' ')
    : '';

  // Exception for the page name header
  if (pageName === 'Rules And Terms') {
    pageName = '';
  }

  // If custom page, set page name to the title of the custom page.
  if (location.pathname.split('/')[1] === 'custom-pages' && customPages?.length) {
    const customPageId = location.pathname.split('/')[2];
    if (customPageId) {
      const customPage = customPages.find((page) => page.id.toString() === customPageId.toString());
      if (customPage) {
        pageName = customPage.title;
      }
    }
  }

  useEffect(() => setDomLoaded(true), []);

  useEffect(() => {
    if (toast && toast.length > 0) {
      setTimeout(() => {
        toast.forEach((toast) =>
          notify(toast?.data ? <PointsNotifier event={toast.data} /> : toast.message, { type: toast?.type ?? 'info' })
        );
      }, 1000);
    }
  }, [toast]);

  // Only run this once or we risk an infinte loop.
  // This catches hash routes linked from journeys and
  // redirects them appropriately.
  // Note: ESLint rule disabled because we explicitly want to run this once.
  useEffect(() => {
    if (potentialRedirect) {
      navigate(location.hash.substring(1));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setModalOpen(requiresOnboarding);
  }, [requiresOnboarding]);

  // TODO: This is a temporary fix to prevent the loading style from being applied while the user is submitting
  // answers on the Financial Wellness form. We need a more nuanced approach to loading indicators.
  const isLoading = navigation.formAction !== '/assessments/financial-wellness' && navigation.state === 'loading';

  // Ignore the /login route. Start tracking once the user lands on the dashboard after login.
  useEffect(() => {
    if (
      navigation.state === 'loading' &&
      fetcher.state === 'idle' &&
      navigation.location?.pathname !== previousPage &&
      navigation.location.pathname !== '/login'
    ) {
      fetcher.load('/viewPage');
      setPreviousPage(navigation.location.pathname);
    }
  }, [fetcher, navigation.location, navigation.state, previousPage]);

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta httpEquiv="Content-Type" content="text/html;charset=utf-8" />
        <Meta />
        <Links />
        {matches
          .filter((match) => match?.handle?.companyColors)
          .map((match, index) => (
            <style key={index} type="text/css">
              {match.handle.companyColors(match)}
            </style>
          ))}
      </head>
      <body>
        {/* Google Analytics */}
        <script async src={`https://www.googletagmanager.com/gtag/js?id=${ENV.GA_MEASUREMENT_ID}`} />
        <script
          dangerouslySetInnerHTML={{
            __html: `
               window.dataLayer = window.dataLayer || [];
               function gtag(){dataLayer.push(arguments);}
               gtag('js', new Date());
               gtag('config', '${ENV.GA_MEASUREMENT_ID}');
            `,
          }}
        />

        {potentialRedirect ? (
          <h1>Loading page...</h1>
        ) : (
          <>
            {isAuthRoute && (
              <Container
                id="public"
                fluid
                className="d-flex flex-column w-100 m-0 p-0 align-items-center justify-content-center h-100"
              >
                <ToastContainer />
                <Outlet />
              </Container>
            )}

            {!isAuthRoute && (
              <>
                {(!company.logo_orientation || company.logo_orientation === LANDSCAPE) && (
                  <Navbar companyLogoURL={companyLogoURL} pageName={pageName} orientation={company.logo_orientation} />
                )}
                <Container fluid className="d-flex flex-column w-100 p-0 justify-content-between h-100 main-container">
                  <ToastContainer />
                  <Container fluid="lg" className="flex-grow-1">
                    <Row>
                      <Col xs={12} md={4} className="site-left-col">
                        <div className="d-none d-md-block">
                          <LeftMenu />
                        </div>
                      </Col>
                      <Col xs={12} md={8} lg={true}>
                        {company?.logo_orientation === PORTRAIT && (
                          <Navbar
                            companyLogoURL={companyLogoURL}
                            pageName={pageName}
                            orientation={company.logo_orientation}
                          />
                        )}
                        <div id="main-content" className={isLoading ? 'loading' : ''}>
                          <h1 className="d-sm-none">{pageName}</h1>
                          <Outlet />
                        </div>
                      </Col>
                    </Row>
                  </Container>
                  <Footer company={company} />
                  {page === '' && <OnboardingModal isOpen={modalOpen} onExit={handleOnExit} profile={profile} />}
                </Container>
              </>
            )}
          </>
        )}

        <script
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(ENV)}`,
          }}
        />
        <script src={ENV?.PAYITOFF_SCRIPT_URL} />
        <ExternalScripts />
        <Scripts />
        <ScrollRestoration />
        <script
          language="JavaScript"
          type="text/javascript"
          src="/assets/equifax/efx-consumer-api-client_0.1.3.js"
        ></script>
        <script language="JavaScript" type="text/javascript" src="/assets/dinky/KJE.js"></script>
        <script language="JavaScript" type="text/javascript" src="/assets/dinky/KJESiteSpecific.js"></script>
        <script src="https://kit.fontawesome.com/a5e7c72b0d.js" crossOrigin="anonymous"></script>
      </body>
    </html>
  );
}

export default withSentry(App);

export const ErrorBoundary = () => {
  const error = useRouteError();

  captureRemixErrorBoundaryError(error);

  useEffect(() => {
    if (error.status === 404) {
      window.location.href = '/';
    }
  }, [error.status]);

  return (
    <div>
      {error.status !== 404 && (
        <h1>
          {error.status} {error.statusText}
        </h1>
      )}
      <Scripts />
    </div>
  );
};
