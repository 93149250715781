function PointsMessage({ notificationData }) {
  return (
    <>
      <strong>{notificationData.contest}</strong>
      <h2> + {notificationData.points} </h2>
      <strong>{notificationData.message}</strong>
    </>
  );
}

function MaxPointsMessage({ notificationData }) {
  return (
    <>
      <strong>{notificationData.contest}</strong>
      <h2>
        <i className="fa fa-exclamation-circle" aria-hidden="true" />
      </h2>
      <strong>{notificationData.message}</strong>
    </>
  );
}

function PointsNotifier({ event }) {
  const data = JSON.parse(event?.data) || null;

  if (data) {
    if (data?.points > 0) {
      return <PointsMessage notificationData={data} />;
    }
    if (data?.points === 0) {
      return <MaxPointsMessage notificationData={data} />;
    }
  }
}

export default PointsNotifier;
