import PropTypes from 'prop-types';
import CreditScoreWidget from '../CreditScoreWidget';
import { useLoaderData } from '@remix-run/react';
import { useCallback } from 'react';

const Step2 = ({ handleNext }) => {
  const { efxClientLibSettings, profile } = useLoaderData();

  const handleComplete = useCallback(() => {
    handleNext();
  }, [handleNext]);

  return (
    <>
      <div className="d-flex justify-content-center align-items-center flex-column">
        <CreditScoreWidget efxClientLibSettings={efxClientLibSettings} profile={profile} onComplete={handleComplete} />
      </div>
    </>
  );
};

Step2.propTypes = {
  handleNext: PropTypes.func.isRequired,
};

export default Step2;
