import Question from '../FinancialWellnessStepper/Question';
import { SAVE_FINANCIAL_WELLNESS_ANSWER } from '../../routes/assessments.financial-wellness/constants.js';

const step3Questions = (assessment) =>
  assessment?.questions.map((q, i) => {
    return {
      id: `ASSESSMENT_${i}`,
      header: (
        <>
          <h2>Quick Financial Wellness Score</h2>
        </>
      ),
      component: ({ formValues, setFormValues, canProceed, goToStep }) => (
        <Question
          questionNumber={i + 1}
          question={q}
          formValues={formValues}
          setFormValues={setFormValues}
          isLastQuestion={assessment?.questions.length - 1 === i}
          setCanProceed={canProceed}
          goToStep={goToStep}
        />
      ),
      intent: SAVE_FINANCIAL_WELLNESS_ANSWER,
    };
  });

export default step3Questions;
