import { useState, Fragment, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useRouteLoaderData, NavLink, useNavigation } from '@remix-run/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Nav, NavItem, Popover, PopoverBody } from 'reactstrap';
import {
  faHouse,
  faFlagCheckered,
  faHeartPulse,
  faMoneyBillWave,
  faGaugeHigh,
  faLocationDot,
  faDisplay,
  faUser,
  faTableCellsLarge,
  faChartSimple,
  faQuestionCircle,
  faTags,
} from '@fortawesome/free-solid-svg-icons';
import LeftMenuDropdown from './LeftMenuDropdown';
import { ADMIN_ROLES } from '../utils/roles';
import Logo from '../assets/images/bmm-logo-color.png';
import iconCyber from '../assets/images/icon_cyber.png';
import '../style/components/leftMenu.css';

export default function LeftMenu({ showSubroutes = false, toggle, isOpen }) {
  const { user, company, companyLogoURL, customPages, hasCreditScoreAccess } = useRouteLoaderData('root') ?? {};
  const [popoverOpen, setPopoverOpen] = useState(false);
  const popoverRef = useRef(null);

  const togglePopover = () => setPopoverOpen(!popoverOpen);

  const navigation = useNavigation();

  const navItems = [
    {
      to: '/',
      icon: faHouse,
      title: 'Home',
    },
    {
      to: '/goals/money',
      icon: faFlagCheckered,
      title: 'Goals',
      subroutes: [
        {
          to: '/goals/money',
          title: 'Money',
        },
        {
          to: '/goals/career',
          title: 'Career',
        },
        {
          to: '/goals/life',
          title: 'Life',
        },
        {
          to: '/goals/retirement',
          title: 'Retirement',
        },
      ],
    },
    {
      to: '/assessments/stressometer',
      icon: faHeartPulse,
      title: 'Assessments',
      subroutes: [
        {
          to: '/assessments/stressometer',
          title: 'Stressometer',
        },
        hasCreditScoreAccess && {
          to: '/assessments/credit-score',
          title: 'Credit Score',
        },
        {
          to: '/assessments/financial-wellness',
          title: 'Financial Wellness',
        },
        {
          to: '/assessments/cyber-score',
          title: 'Cybersecurity score',
        },
      ],
    },
    {
      to: '/budget/income',
      icon: faMoneyBillWave,
      title: 'Budget & Money',
      subroutes: [
        {
          to: '/budget/income',
          title: 'Income',
        },
        {
          to: '/budget/expenses',
          title: 'Expenses',
        },
        {
          to: '/budget/savings',
          title: 'Savings',
        },
        {
          to: '/budget/debt',
          title: 'Debt',
        },
      ],
    },
    {
      to: `/deals-and-discounts/${company.has_best_money_moves_premium ? 'partners' : 'benefits'}`,
      icon: faTags,
      title: 'Deals & Discounts',
      isEnabled: true,
    },
    {
      to: '/assessments/credit-score',
      icon: faGaugeHigh,
      title: 'Credit Score',
      isEnabled: hasCreditScoreAccess,
    },
    {
      to: `/assessments/cyber-score`,
      icon: iconCyber,
      title: 'Cybersecurity',
      isCustomIcon: true,
    },
    {
      to: '/journeys/journeys',
      icon: faLocationDot,
      title: 'Journeys',
    },
    {
      to: '/learn/read',
      icon: faDisplay,
      title: 'Learn',
      subroutes: [
        {
          to: '/learn/read',
          title: 'Read',
        },
        {
          to: '/learn/watch',
          title: 'Watch',
        },
        {
          to: '/learn/listen',
          title: 'Listen',
        },
        {
          to: '/learn/calculate',
          title: 'Calculate',
        },
      ],
    },
    {
      to: '/account/account',
      icon: faUser,
      title: 'Account',
      subroutes: [
        {
          to: '/account/account',
          title: 'Account',
        },
        {
          to: '/account/security',
          title: 'Security',
        },
        company.available_contests?.toLowerCase() !== 'none' && {
          to: '/account/contests',
          title: 'Contests',
        },
      ],
    },
    ...(customPages?.length
      ? customPages.map((customPage) => ({
          to: `/custom-pages/${customPage.id}`,
          icon: faTableCellsLarge,
          title: customPage.title || 'Custom',
        }))
      : []),
    {
      to: '/analytics/scores',
      icon: faChartSimple,
      title: 'Analytics',
      isEnabled: user.roles.some((role) => ADMIN_ROLES.includes(role)),
      subroutes: [
        {
          to: '/analytics/engagement',
          title: 'Engagement',
        },
        {
          to: '/analytics/messaging',
          title: 'Messaging',
        },
        {
          to: '/analytics/reports',
          title: 'Reports',
        },
        {
          to: '/analytics/scores',
          title: 'Scores',
        },
      ],
    },
  ];

  //close popover when user click away
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popoverRef.current && !popoverRef.current.contains(event.target)) {
        setPopoverOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // If we're navigating and the menu is open, close it.
  useEffect(() => {
    if (navigation.state !== 'idle' && isOpen) {
      toggle && toggle();
    }
  }, [navigation.state, toggle, isOpen]);

  return (
    <>
      <Nav
        vertical
        className={`${company?.logo_orientation === 'portrait' ? 'left-menu-portrait' : 'left-menu'} nav-fill`}
      >
        {company?.logo_orientation === 'portrait' && (
          <NavLink to="/" className="d-none d-sm-block py-2">
            <img alt="logo" src={companyLogoURL ?? Logo} className="portrait-logo" />
          </NavLink>
        )}

        {navItems.map(({ to, icon, title, isEnabled, isCustomIcon, subroutes }, i) => {
          // If the link requires an feature to be enabled,
          // only render it if the user's company has the requiredFeature enabled
          if (isEnabled === false) {
            return null;
          }

          return (
            <Fragment key={`${title}-${i}`}>
              {(!showSubroutes || !subroutes || subroutes?.length === 0) && (
                <NavItem>
                  <NavLink className="icon-link nav-link text-black link-underline-none" to={to} prefetch="viewport">
                    {icon ? (
                      isCustomIcon ? (
                        <img src={icon} alt={title} width="18" />
                      ) : (
                        <FontAwesomeIcon icon={icon} />
                      )
                    ) : (
                      <span />
                    )}
                    {title}
                  </NavLink>
                </NavItem>
              )}
              {showSubroutes && subroutes?.length > 0 && (
                <LeftMenuDropdown icon={icon} isCustomIcon={isCustomIcon} title={title} subroutes={subroutes} />
              )}
            </Fragment>
          );
        })}

        <NavItem>
          <NavLink to={'/logout'} className="icon-link nav-link no-icon">
            Logout
          </NavLink>
        </NavItem>

        {company.has_money_coach && (
          <NavItem className="mt-4">
            <div className="panel-body">
              <p className="mb-0 text-black text-center mt-2">
                Money Coach Number
                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  id={`moneyCoachPopover${showSubroutes ? 'Top' : 'Left'}`}
                  className="ms-2 question-icon"
                  onClick={togglePopover}
                />
              </p>
              <h3 className="money-coach-number text-center ng binding mb-0">{company?.money_coach_number}</h3>
              <p className="text-black text-center">Free + Confidential</p>
            </div>
            <Popover
              placement="right"
              isOpen={popoverOpen}
              target={`moneyCoachPopover${showSubroutes ? 'Top' : 'Left'}`}
              toggle={togglePopover}
              innerRef={popoverRef}
            >
              <PopoverBody>
                Call our Money Coaches for help with specific financial issues or even just building a budget. All calls
                are completely confidential from us and your employer.
                <br />
                <br />
                Hours of Operation:
                <br />
                Monday to Friday: 8:30am - 10:30pm EST
                <br />
                Saturday: 9am - 9pm EST
                <br />
                Sunday: 9am - 7pm EST
              </PopoverBody>
            </Popover>
          </NavItem>
        )}
      </Nav>
    </>
  );
}

LeftMenu.propTypes = {
  showSubroutes: PropTypes.bool,
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
};
