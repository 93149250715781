function convertHexToRGB(hexColor) {
  if (hexColor.length === 7) {
    return hexColor
      .slice(1)
      .match(/.{1,2}/g)
      .map((v) => Number(`0x${v}`));
  } else if (hexColor.length === 4) {
    return hexColor
      .slice(1)
      .match(/.{1,1}/g)
      .map((v) => Number(`0x${v}`) * 16);
  }

  return null;
}

// This is (apparently) based on the W3C's implementation of determining color brightness.
// Essentially this compares the color's brightness to 50% of white's brightness.
// If it's below, we return white. If above, we return black.
function getTextColor(hexColor) {
  if (!hexColor) {
    return null;
  }

  const rgb = convertHexToRGB(hexColor);
  const [r, g, b] = rgb;
  const brightness = Math.round(r * 299 + g * 587 + (b * 114) / 1000);
  const whiteBrightness = Math.round(255 * 299 + 255 * 587 + (255 * 114) / 1000);

  return Math.abs(brightness) < whiteBrightness / 2 ? '#ffffff' : '#000000';
}

export function getCompanyColorsCSS(company) {
  return `
    :root {
      ${
        company.enable_color_primary
          ? `
          --bmm-primary: ${company.color_primary};
          --bmm-primary-rgb: ${convertHexToRGB(company.color_primary)};
          --bmm-primary-text-color: ${getTextColor(company.color_primary)};
        `
          : ''
      }
      ${
        company.enable_color_secondary
          ? `
          --bmm-secondary: ${company.color_secondary};
          --bmm-secondary-rgb: ${convertHexToRGB(company.color_secondary)};
          --bmm-secondary-text-color: ${getTextColor(company.color_secondary)};
        `
          : ''
      }
      ${
        company.enable_color_tertiary
          ? `
          --bmm-tertiary: ${company.color_tertiary};
          --bmm-tertiary-rgb: ${convertHexToRGB(company.color_tertiary)};
          --bmm-tertiary-text-color: ${getTextColor(company.color_tertiary)};
        `
          : ''
      }
      ${
        company.enable_color_success
          ? `
          --bmm-success: ${company.color_success};
          --bmm-success-rgb: ${convertHexToRGB(company.color_success)};
          --bmm-success-text-color: ${getTextColor(company.color_success)};
        `
          : ''
      }
      ${
        company.enable_color_warning
          ? `
          --bmm-warning: ${company.color_warning};
          --bmm-warning-rgb: ${convertHexToRGB(company.color_warning)};
          --bmm-warning-text-color: ${getTextColor(company.color_warning)};
        `
          : ''
      }
      ${
        company.enable_color_danger
          ? `
          --bmm-danger: ${company.color_danger};
          --bmm-danger-rgb: ${convertHexToRGB(company.color_danger)};
          --bmm-danger-text-color: ${getTextColor(company.color_danger)};
        `
          : ''
      }
    }
  `;
}
