const Congratulations = () => {
  return (
    <>
      <div className="text-center">
        <p className="h5 font-weight-light mb-4">
          In the top right corner of your dashboard you&apos;ll find your recommended reading list.
        </p>
        <p className="h5 font-weight-light mb-4">Click on SAVE to go to your dashboard.</p>
        <p className="h5 font-weight-light">Welcome aboard!</p>
      </div>
    </>
  );
};

export default Congratulations;
